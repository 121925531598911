export const campaignStatusMapping = () => ({
  pending: 'Pendiente',
  ready: 'Lista'
});

export const paymentStatusMapping = () => ({
  pending: 'Pendiente',
  approved: 'Aprobado',
  in_process: 'Procesando',
  in_mediation: 'En mediación',
  rejected: 'Rechazado',
  cancelled: 'Cancelado',
  refunded: 'Reintegrado',
  charge_back: 'Contracargo'
});

export const campaignInvoicesMapping = () => ({
  0: 'No facturado',
  1: 'Facturado'
});

export const campaignChargesMapping = () => ({
  0: 'No cobrado',
  1: 'Cobrado'
});

export const agencyCommissionModeMapping = () => ({
  platform_invoice_payment: 'Factura y pago Latinad',
  platform_invoice: 'Factura LatinAd',
  no_invoice: 'No se factura',
  '-': '-'
});

export const paymentModeMapping = () => ({
  always_pay_full_po: 'Paga 100% ODC siempre',
  pay_by_consumption: 'Paga por consumo',
  '-': '-'
});

export const billingMomentMapping = () => ({
  full_campaign_start_100: 'Inicio de campaña completo por el 100%',
  campaign_start_partial_months: 'Inicio de campaña parcial por meses',
  campaign_completed_no_monthly_break: 'Terminada la campaña sin corte mensual',
  monthly_closure: 'Cierre mensual',
  '-': '-'
});

export const companyTypeMapping = () => ({
  agency: 'Agencia',
  direct_brand: 'Marca directa',
  publisher: 'Publisher',
  '-': '-'
});

export const simplePaymentStatusMapping = () => {
  return {
    0: 'No pagado',
    1: 'Pagado'
  }
}

export const programmaticProviderStatusMapping = () => {
  return {
    '-': '-',
    'latinad': 'LatinAD',
    'place_exchange': 'Place Exchange',
    'magnite': 'Magnite',
    'perion': 'Perion',
    'beeyond': 'Beyeond',
    'outcon': 'Outcon',
    'taggify': 'Taggify',
    'moving_walls': 'Moving Walls',
    'vistar_media': 'Vistar Media',
    'prodooh': 'Prodooh'
  }
}
