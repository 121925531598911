<template>
  <div>
    <!-- ROW 1-->
    <div class="vx-row" v-can="'show_statistic_kpi_displays'">
      <!-- Displays -->
      <div @click="showCardsCountries(card.id)" :id="card.id" v-for="(card, index) in cardsDisplays" :key="`${card.id}_${index}`" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 vs-con-loading__container cursor-pointer">
        <vx-tooltip color="dark" :text="card.tooltipText">
          <statistics-card-line
            hideChart
            icon-right
            class="mb-base"
            :icon="card.icon"
            :color="card.color"
            :statistic="card.value"
            :statisticTitle="card.name" />
          </vx-tooltip>
      </div>
    </div>
    <!-- ROW HIDE-->
    <div :id="`${card.id}_country`" v-for="(card, index) in cardsDisplays" :key="`${card.id}_${index}_hidden`" class="vx-row">
      <div v-show="card.vShowCountries" class="vx-col w-1/2 md:w-1/3 xl:w-1/6" v-for="(element, index2) in card.countries" :key="`${card.id}_${index2}_country`" >
        <statistics-card-line
            hideChart
            class="mb-base"
            :icon="card.icon"
            :color="card.color"
            :statistic="element.total_count"
            :statisticTitle="element.country" />
      </div>
    </div>
    <!-- Campaigns -->
    <div class="vx-col w-full mb-base" v-can="'show_statistic_kpi_campaigns'">
      <vx-tooltip color="dark" :text="strDateCampaigns">
        <vx-card title="Campañas">
          <!-- Filter -->
          <template slot="actions">
            <!-- <change-time-duration-dropdown /> -->
            <vs-dropdown vs-custom-content vs-trigger-click>
              <a class="flex items-center" href.prevent>
                <span>Filtrar fechas</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4 ml-1" />
              </a>
              <vs-dropdown-menu>
                <flat-pickr width="100%" class="mb-1 w-full" :config="configDateRangeCampaignsTimePicker" v-model="dateRangeCampaigns" placeholder="Rango" @on-change="onDateRangeCampaignsChange" />
                <vs-dropdown-item>
                  <vs-button vs-trigger-close width="100%"  @click="getCampaignsData()" color="primary" type="filled" class="mt-4 w-full" >Filtrar</vs-button>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>

          <div slot="no-body">
            <div id="campaigns_stats" class="vx-row text-center vs-con-loading__container">
              <div @click="popupActiveCampaigns=true" class="vx-col w-full lg:w-1/5 md:w-full sm:w-1/5 flex flex-col justify-between mb-4 order-first cursor-pointer">
                <div class="lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6">
                  <h1 class="font-bold text-5xl">{{stats.campaigns_active_count}}</h1>
                  <small>Activas</small>
                </div>
              </div>
              <div class="vx-col w-full lg:w-1/5 md:w-full sm:w-1/5 flex flex-col justify-between mb-4 ">
                <div class="lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6">
                  <h1 class="font-bold text-5xl">{{stats.display_campaigns_active}}</h1>
                  <small>Pantallas</small>
                </div>
              </div>
              <div class="vx-col w-full lg:w-1/5 md:w-full sm:w-1/5 flex flex-col justify-between mb-4 ">
                <div class="lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6">
                  <h1 class="font-bold text-5xl">{{stats.content_display_campaigns_active}}</h1>
                  <small>Anuncios</small>
                </div>
              </div>
              <div class="vx-col w-full lg:w-1/5 md:w-full sm:w-1/5 flex flex-col justify-between mb-4 ">
                <div class="lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6">
                  <h1 class="font-bold text-5xl">{{stats.content_display_approved}}</h1>
                  <small>Anuncios Aprobados</small>
                </div>
              </div>
              <div class="vx-col w-full lg:w-1/5 md:w-full sm:w-1/5 flex flex-col justify-between mb-4 ">
                <div class="lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6">
                  <h1 class="font-bold text-5xl">{{stats.content_display_rejected}}</h1>
                  <small>Anuncios No Aprobados</small>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </vx-tooltip>
    </div>
    <!-- USD -->
    <div class="vx-row" v-can="'show_statistic_kpi_campaigns_profits'">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container text-success">
        <vx-tooltip color="dark" :text="strDateCampaigns">
          <statistics-card-line
            hideChart
            icon-right
            class="mb-base"
            icon="DollarSignIcon"
            color="success"
            colorStatistic="success"
            :statistic="stats.platform_profit_usd"
            statisticColor="success"
            statisticTitle="Ganancia USD (LatinAd)" />
          </vx-tooltip>
      </div>
      <!-- <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer" @click="stats.total_price_with_commission_usd_by_country.length > 0 ? popupTotalPriceWithCommissionUsdByCountry=true : popupTotalPriceWithCommissionUsdByCountry=false">
        <vx-tooltip color="dark" :text="strDateCampaigns">
          <statistics-card-line
            hideChart
            icon-right
            class="mb-base"
            icon="CornerUpLeftIcon"
            color="warning"
            :statistic="stats.total_price_with_commission_usd"
            statisticTitle="Monto a cobrar" />
        </vx-tooltip>
      </div> -->
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer" @click="stats.total_price_with_profit_usd_by_country.length > 0 ? popupTotalPriceWithProfitUsdByCountry=true : popupTotalPriceWithProfitUsdByCountry=false">
        <vx-tooltip color="dark" :text="strDateCampaigns">
          <statistics-card-line
            hideChart
            icon-right
            class="mb-base"
            icon="ShoppingBagIcon"
            color="success"
            :statistic="stats.total_price_with_profit_usd"
            statisticTitle="Facturación total USD" />
        </vx-tooltip>
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container">
        <vx-tooltip color="dark" :text="strDateCampaigns">
          <statistics-card-line
            hideChart
            icon-right
            class="mb-base"
            icon="CornerUpRightIcon"
            color="danger"
            :statistic="stats.total_price_usd"
            statisticTitle="Monto a pagar dueños de medios" />
        </vx-tooltip>
      </div>
    </div>
    <!-- ARS -->
    <div class="vx-row" v-can="'show_statistic_kpi_campaigns_profits'">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container text-primary">
        <vx-tooltip color="dark" :text="strDateCampaigns">
          <statistics-card-line
            hideChart
            icon-right
            class="mb-base"
            icon="DollarSignIcon"
            colorStatistic="primary"
            :statistic="stats.platform_profit_ars"
            statisticTitle="Ganancia ARS" />
          </vx-tooltip>
      </div>
      <!-- <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer" @click="stats.total_price_with_commission_ars_by_country.length > 0 ? popupTotalPriceWithCommissionArsByCountry=true : popupTotalPriceWithCommissionArsByCountry=false">
        <vx-tooltip color="dark" :text="strDateCampaigns">
          <statistics-card-line
            hideChart
            icon-right
            class="mb-base"
            icon="CornerUpLeftIcon"
            color="warning"
            :statistic="stats.total_price_with_commission_ars"
            statisticTitle="Monto a cobrar" />
        </vx-tooltip>
      </div> -->
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer" @click="stats.total_price_with_profit_ars_by_country.length > 0 ? popupTotalPriceWithProfitArsByCountry=true : popupTotalPriceWithProfitArsByCountry=false">
        <vx-tooltip color="dark" :text="strDateCampaigns">
          <statistics-card-line
            hideChart
            icon-right
            class="mb-base"
            icon="ShoppingBagIcon"
            :statistic="stats.total_price_with_profit_ars"
            statisticTitle="Facturación total ARS" />
        </vx-tooltip>
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container">
        <vx-tooltip color="dark" :text="strDateCampaigns">
          <statistics-card-line
            hideChart
            icon-right
            class="mb-base"
            icon="CornerUpRightIcon"
            color="danger"
            :statistic="stats.total_price_ars"
            statisticTitle="Monto a pagar dueños de medios" />
        </vx-tooltip>
      </div>
    </div>
    <!-- Popup Table Active Campaigns-->
    <vs-popup title="Campañas activas" :active.sync="popupActiveCampaigns">
      <vs-table noDataText="Sin datos" :data="stats.campaigns_active">
        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>Nombre</vs-th>
          <vs-th>Tipo</vs-th>
          <vs-th>Fecha inicio</vs-th>
          <vs-th>Fecha fin</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :state="data[indextr].smart_campaign ? 'primary' : null" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].id">
              {{ data[indextr].id }}
            </vs-td>
            <vs-td :data="data[indextr].name">
              {{ data[indextr].name }}
            </vs-td>
            <vs-td :data="data[indextr].type_campaign">
              {{ data[indextr].type_campaign }}
            </vs-td>
            <vs-td :data="data[indextr].start_date">
              {{ data[indextr].start_date | dateFormatter }}
            </vs-td>
            <vs-td :data="data[indextr].end_date">
              {{ data[indextr].end_date | dateFormatter }}
            </vs-td>
            <template class="expand-user" slot="expand">
              <div class="con-expand-users w-full">
                <div class="con-btns-user flex items-center justify-between">
                  <div class="con-userx flex items-center justify-start">
                    <span>Fecha creación: {{ tr.created_at | dateFormatter }}</span>
                  </div>
                </div>
              </div>
            </template>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
    <!-- Popup USD-->
    <!-- <vs-popup title="Monto a cobrar en USD por país" :active.sync="popupTotalPriceWithCommissionUsdByCountry">
      <div class="vx-col w-full vs-con-loading__container"  v-for="(value, index) in stats.total_price_with_commission_usd_by_country" :key="`total_price_with_commission_usd_by_country_${index}`">
        <statistics-card-line
          hideChart
          icon-right
          class="mb-base"
          icon="CornerUpLeftIcon"
          color="warning"
          :statistic="value.sum"
          :statisticTitle="value.country" />
      </div>
    </vs-popup> -->
    <!-- Popup ARS-->
    <!-- <vs-popup title="Monto a cobrar en ARS por país" :active.sync="popupTotalPriceWithCommissionArsByCountry">
      <div class="vx-col w-full vs-con-loading__container"  v-for="(value, index) in stats.total_price_with_commission_ars_by_country" :key="`total_price_with_commission_ars_by_country_${index}`">
        <statistics-card-line
          hideChart
          icon-right
          class="mb-base"
          icon="CornerUpLeftIcon"
          color="warning"
          :statistic="value.sum"
          :statisticTitle="value.country" />
      </div>
    </vs-popup> -->
    <!-- Popup USD-->
    <vs-popup title="Facturacion en USD por país" :active.sync="popupTotalPriceWithProfitUsdByCountry">
      <div class="vx-col w-full vs-con-loading__container"  v-for="(value, index) in stats.total_price_with_profit_usd_by_country" :key="`total_price_with_profit_usd_by_country_${index}`">
        <statistics-card-line
          hideChart
          icon-right
          class="mb-base"
          icon="ShoppingBagIcon"
          color="success"
          :statistic="value.sum"
          :statisticTitle="value.country" />
      </div>
    </vs-popup>
    <!-- Popup ARS-->
    <vs-popup title="Facturacion en ARS por país" :active.sync="popupTotalPriceWithProfitArsByCountry">
      <div class="vx-col w-full vs-con-loading__container"  v-for="(value, index) in stats.total_price_with_profit_ars_by_country" :key="`total_price_with_profit_usd_by_country_${index}`">
        <statistics-card-line
          hideChart
          icon-right
          class="mb-base"
          icon="ShoppingBagIcon"
          :statistic="value.sum"
          :statisticTitle="value.country" />
      </div>
    </vs-popup>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey('CompanyName=Servoy B.V.,LicensedApplication=Servoy,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=200,AssetReference=AG-010463,ExpiryDate=11_October_2021_[v2]_MTYzMzkwNjgwMDAwMA==4c6752fe4cb2066ab1f0e9c572bc7491')
import appConfig from '@/../appConfig.js'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import flatPickr from 'vue-flatpickr-component'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'

export default {
  components: {
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    flatPickr
  },
  data () {
    return {
      cardsDisplays: [],

      stats: {
        displays_active: [],
        displays_active_count: '-',
        company_with_displays_active: [],
        company_with_displays_active_count: '-',

        displays_disabled: [],
        displays_disabled_count: '-',
        company_with_displays_disabled: [],
        company_with_displays_disabled_count: '-',

        displays_for_sale: [],
        displays_for_sale_count: '-',
        displays_active_for_sale: [],
        displays_active_for_sale_count: '-',
        displays_disabled_for_sale: [],
        displays_disabled_for_sale_count: '-',

        displays_not_use_cms: [],
        displays_not_use_cms_count: '-',

        displays_active_camlytics: [],
        displays_active_camlytics_count: '-',

        campaigns_active: [],
        campaigns_active_count: '-',
        content_display_campaigns_active: '-',
        content_display_approved: '-',
        content_display_rejected: '-',
        display_campaigns_active: '-',
        // ARS
        total_price_ars: '-',
        total_price_with_commission_ars: '-',
        total_price_with_commission_ars_by_country: [],
        total_price_with_profit_ars: '-',
        total_price_with_profit_ars_by_country: [],
        platform_profit_ars: '-',
        // USD
        total_price_usd: '-',
        total_price_with_commission_usd: '-',
        total_price_with_commission_usd_by_country: [],
        total_price_with_profit_usd: '-',
        total_price_with_profit_usd_by_country: [],
        platform_profit_usd: '-'
      },

      showListCountryDisplayActive: false,
      showListCountryDisplayActiveForSale: false,
      showListCountryCompanyWithDisplayActive:false,
      showListCountryDisplayActiveCamlytics: false,
      showListCountryDisplaysDisabled: false,
      showListCountryCompanyWithDisplaysDisabled:false,

      configDateRangeCampaignsTimePicker: {
        locale: SpanishLocale,
        minDate: null,
        maxDate: new Date(),
        mode: 'range',
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        defaultDate: [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        inline: true
      },

      dateRangeCampaigns: null,
      filterDateCampaigns: {
        from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      },
      strDateCampaigns: '',

      // Popups
      popupActiveCampaigns: false,
      popupTotalPriceWithCommissionUsdByCountry: false,
      popupTotalPriceWithCommissionArsByCountry: false,
      popupTotalPriceWithProfitUsdByCountry: false,
      popupTotalPriceWithProfitArsByCountry: false
    }
  },
  watch: {
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    },
    isSuperAdmin () {
      return this.activeUserInfo.roles.includes('super-admin')
    }
  },
  methods: {
    getDisplaysData () {
      this.openLoadingDisplaysCards()
      this.$http.get(`${appConfig.apiUrl}/stats/kpi`, {
        params: {
          filters: {
            statisticType: 'displays',
            last_days: 7 // TODO: change when filter is implemented for days
          }
        }
      })
        .then((response) => {

          // Displays active last days
          this.stats.displays_active = response.data.displays_active
          if (this.stats.displays_active.length > 0) {
            this.stats.displays_active_count = 0

            this.stats.displays_active.forEach((item) => {
              this.stats.displays_active_count += item.total_count
            })
          }

          const auxDisplaysActive = {
            id: 'displays_active',
            name: 'Pantallas activas',
            value: this.stats.displays_active_count,
            icon: 'MonitorIcon',
            color: 'primary',
            countries: this.stats.displays_active,
            tooltipText: 'En los últimos 7 días',
            vShowCountries: false
          }
          this.cardsDisplays.push(auxDisplaysActive)

          // Displays for sales
          this.stats.displays_for_sale = response.data.displays_for_sale
          if (this.stats.displays_for_sale.length > 0) {
            this.stats.displays_for_sale_count = 0

            this.stats.displays_for_sale.forEach((item) => {
              this.stats.displays_for_sale_count += item.total_count
            })
          }

          const auxDisplaysForSales = {
            id: 'displays_for_sales',
            name: 'Pantallas a la venta',
            value: this.stats.displays_for_sale_count,
            icon: 'MonitorIcon',
            color: 'primary',
            countries: this.stats.displays_for_sale,
            tooltipText: 'Pantallas que están a la venta',
            vShowCountries: false
          }
          this.cardsDisplays.push(auxDisplaysForSales)

          // Displays active for sales last days
          this.stats.displays_active_for_sale = response.data.displays_active_for_sale
          if (this.stats.displays_active_for_sale.length > 0) {
            this.stats.displays_active_for_sale_count = 0

            this.stats.displays_active_for_sale.forEach((item) => {
              this.stats.displays_active_for_sale_count += item.total_count
            })
          }

          const auxDisplaysActiveForSales = {
            id: 'displays_active_for_sale',
            name: 'Pantallas activas a la venta',
            value: this.stats.displays_active_for_sale_count,
            icon: 'MonitorIcon',
            color: 'success',
            countries: this.stats.displays_active_for_sale,
            tooltipText: 'Pantallas activas los últimos 7 días que están a la venta',
            vShowCountries: false
          }
          this.cardsDisplays.push(auxDisplaysActiveForSales)

          // Displays disabled for sales last days
          this.stats.displays_disabled_for_sale = response.data.displays_disabled_for_sale
          if (this.stats.displays_disabled_for_sale.length > 0) {
            this.stats.displays_disabled_for_sale_count = 0

            this.stats.displays_disabled_for_sale.forEach((item) => {
              this.stats.displays_disabled_for_sale_count += item.total_count
            })
          }

          const auxDisplaysDisabledForSales = {
            id: 'displays_disabled_for_sale',
            name: 'Pantallas inactivas a la venta',
            value: this.stats.displays_disabled_for_sale_count,
            icon: 'MonitorIcon',
            color: 'warning',
            countries: this.stats.displays_disabled_for_sale,
            tooltipText: 'Pantallas inactivas los últimos 7 días que están a la venta',
            vShowCountries: false
          }
          this.cardsDisplays.push(auxDisplaysDisabledForSales)

          // Displays disabled last days
          this.stats.displays_disabled = response.data.disabled_displays
          if (this.stats.displays_disabled.length > 0) {
            this.stats.displays_disabled_count = 0

            this.stats.displays_disabled.forEach((item) => {
              this.stats.displays_disabled_count += item.total_count
            })
          }

          const auxDisplaysDisable = {
            id: 'displays_disabled',
            name: 'Pantallas inactivas',
            value: this.stats.displays_disabled_count,
            icon: 'MonitorIcon',
            color: 'danger',
            countries: this.stats.displays_disabled,
            tooltipText: 'Pantallas inactivas hace 7 días o más',
            vShowCountries: false
          }
          this.cardsDisplays.push(auxDisplaysDisable)

          // Company displays active last days
          this.stats.company_with_displays_active = response.data.company_with_displays_active
          if (this.stats.company_with_displays_active.length > 0) {
            this.stats.company_with_displays_active_count = 0

            this.stats.company_with_displays_active.forEach((item) => {
              this.stats.company_with_displays_active_count += item.total_count
            })
          }

          const auxCompanyDisplaysActive = {
            id: 'company_with_displays_active',
            name: 'Empresas con pantallas activas',
            value: this.stats.company_with_displays_active_count,
            icon: 'UserPlusIcon',
            color: 'primary',
            countries: this.stats.company_with_displays_active,
            tooltipText: 'En los últimos 7 días',
            vShowCountries: false
          }
          this.cardsDisplays.push(auxCompanyDisplaysActive)

          // Company displays disabled last days
          this.stats.company_with_displays_disabled = response.data.company_with_displays_disabled
          if (this.stats.company_with_displays_disabled.length > 0) {
            this.stats.company_with_displays_disabled_count = 0

            this.stats.company_with_displays_disabled.forEach((item) => {
              this.stats.company_with_displays_disabled_count += item.total_count
            })
          }

          const auxCompanyDisplaysDisable = {
            id: 'company_displays_disabled',
            name: 'Empresas con pantallas inactivas',
            value: this.stats.company_with_displays_disabled_count,
            icon: 'UserPlusIcon',
            color: 'danger',
            countries: this.stats.company_with_displays_disabled,
            tooltipText: 'Pantallas inactivas hace 7 días o más',
            vShowCountries: false
          }
          this.cardsDisplays.push(auxCompanyDisplaysDisable)

          // Displays active with camera
          this.stats.displays_active_camlytics = response.data.displays_active_camlytics
          if (this.stats.displays_active_camlytics.length > 0) {
            this.stats.displays_active_camlytics_count = 0

            this.stats.displays_active_camlytics.forEach((item) => {
              this.stats.displays_active_camlytics_count += item.total_count
            })
          }

          const auxDisplaysActiveCamlytics = {
            id: 'displays_active_camlytics',
            name: 'Pantallas activas con cámara',
            value: this.stats.displays_active_camlytics_count,
            icon: 'VideoIcon',
            color: 'success',
            countries: this.stats.displays_active_camlytics,
            tooltipText: 'Pantallas activas los últimos 7 días con camlytics',
            vShowCountries: false
          }
          this.cardsDisplays.push(auxDisplaysActiveCamlytics)

          // Displays not use cms
          this.stats.displays_not_use_cms = response.data.displays_not_use_cms
          if (this.stats.displays_not_use_cms.length > 0) {
            this.stats.displays_not_use_cms_count = 0

            this.stats.displays_not_use_cms.forEach((item) => {
              this.stats.displays_not_use_cms_count += item.total_count
            })
          }

          const auxDisplaysNotUseCms = {
            id: 'displays_not_use_cms',
            name: 'Pantallas sin CMS',
            value: this.stats.displays_not_use_cms_count,
            icon: 'ThumbsDownIcon',
            color: 'danger',
            countries: this.stats.displays_not_use_cms,
            tooltipText: 'Pantallas que no usan el CMS',
            vShowCountries: false
          }
          this.cardsDisplays.push(auxDisplaysNotUseCms)

          this.closeLoadingDisplaysCards()
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          // always executed
        })
    },
    getCampaignsData () {
      this.openLoadingCampaigsCards()
      this.$http.get(`${appConfig.apiUrl}/stats/kpi`, {
        params: {
          filters: {
            statisticType: 'campaigns',
            date: {
              from: this.filterDateCampaigns.from,
              to: this.filterDateCampaigns.to
            }
          }
        }
      })
        .then((response) => {
          this.stats.campaigns_active = response.data.campaigns_active
          this.stats.campaigns_active_count = response.data.campaigns_active.length > 0 ? response.data.campaigns_active.length : '-'
          this.stats.content_display_campaigns_active = response.data.content_display_campaigns_active > 0 ? response.data.content_display_campaigns_active : '-'

          if (response.data.content_display_revision_status_campaigns_active.length > 0) {
            response.data.content_display_revision_status_campaigns_active.forEach((item) => {
              switch (item.revision_status) {
              case 'approved':
                this.stats.content_display_approved = item.total_count
                break
              case 'rejected':
                this.stats.content_display_rejected = item.total_count
                break
              default:
                break
              }
            })
          }

          this.stats.display_campaigns_active = response.data.display_campaigns_active[0].count_display > 0 ? response.data.display_campaigns_active[0].count_display : '-'

          // ARS
          this.stats.total_price_ars = response.data.total_price_ars > 0 ? this.currencyFormatter(response.data.total_price_ars, 'ARS') : '-'
          this.stats.total_price_with_commission_ars = response.data.total_price_with_commission_ars > 0 ? this.currencyFormatter(response.data.total_price_with_commission_ars, 'ARS') : '-'
          this.stats.platform_profit_ars = response.data.platform_profit_ars !== 0 ? this.currencyFormatter(response.data.platform_profit_ars, 'ARS') : '-'
          this.stats.total_price_with_commission_ars_by_country = response.data.total_price_with_commission_ars_by_country
          this.stats.total_price_with_commission_ars_by_country.forEach((item) => {
            item.sum = this.currencyFormatter(item.sum, 'ARS')
            item.country = item.country ? item.country : '-'
          })
          this.stats.total_price_with_profit_ars = response.data.total_price_with_profit_ars > 0 ? this.currencyFormatter(response.data.total_price_with_profit_ars, 'ARS') : '-'
          this.stats.total_price_with_profit_ars_by_country = response.data.total_price_with_profit_ars_by_country
          this.stats.total_price_with_profit_ars_by_country.forEach((item) => {
            item.sum = this.currencyFormatter(item.sum, 'ARS')
            item.country = item.country ? item.country : '-'
          })
          // USD
          this.stats.total_price_usd = response.data.total_price_usd > 0 ? this.currencyFormatter(response.data.total_price_usd, 'USD') : '-'
          this.stats.total_price_with_commission_usd = response.data.total_price_with_commission_usd > 0 ? this.currencyFormatter(response.data.total_price_with_commission_usd, 'USD') : '-'
          this.stats.platform_profit_usd = response.data.platform_profit_usd !== 0 ? this.currencyFormatter(response.data.platform_profit_usd, 'USD') : '-'
          this.stats.total_price_with_commission_usd_by_country = response.data.total_price_with_commission_usd_by_country
          this.stats.total_price_with_commission_usd_by_country.forEach((item) => {
            item.sum = this.currencyFormatter(item.sum, 'USD')
            item.country = item.country ? item.country : '-'
          })
          this.stats.total_price_with_profit_usd = response.data.total_price_with_profit_usd > 0 ? this.currencyFormatter(response.data.total_price_with_profit_usd, 'USD') : '-'
          this.stats.total_price_with_profit_usd_by_country = response.data.total_price_with_profit_usd_by_country
          this.stats.total_price_with_profit_usd_by_country.forEach((item) => {
            item.sum = this.currencyFormatter(item.sum, 'USD')
            item.country = item.country ? item.country : '-'
          })

          this.setStrDateCampaigns()
          this.closeLoadingCampaigsCards()
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          // always executed
        })
    },
    openLoadingDisplaysCards () {
      this.cardsDisplays.forEach((card) => {
        this.$vs.loading({
          container: `#${card.id}`,
          scale: 0.6,
          type: 'border'
        })
      })
    },
    closeLoadingDisplaysCards () {
      this.cardsDisplays.forEach((card) => {
        this.$vs.loading.close(`#${card.id} > .con-vs-loading`)
      })
    },
    openLoadingCampaigsCards () {
      this.$vs.loading({
        container: '#campaigns_stats',
        scale: 0.6,
        type: 'border'
      })
    },
    closeLoadingCampaigsCards () {
      this.$vs.loading.close('#campaigns_stats > .con-vs-loading')
    },
    showCardsCountries (id) {
      this.cardsDisplays.forEach((card) => {
        if (card.id === id) {
          card.vShowCountries = !card.vShowCountries
        } else {
          card.vShowCountries = false
        }
      })
    },
    onDateRangeCampaignsChange (selectedDates) {
      this.filterDateCampaigns.from = moment(selectedDates[0]).format('YYYY-MM-DD')
      this.filterDateCampaigns.to = selectedDates.length > 1 ? moment(selectedDates[1]).format('YYYY-MM-DD') : ''
    },
    setStrDateCampaigns () {
      this.strDateCampaigns = `Desde ${moment(this.filterDateCampaigns.from, 'YYYY-MM-DD').format('DD/MM/YYYY')} hasta ${moment(this.filterDateCampaigns.to, 'YYYY-MM-DD').format('DD/MM/YYYY')}`
    },
    currencyFormatter (value, currency) {
      const twoDecimals = parseFloat(value).toFixed(2)
      const formatted = twoDecimals.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return currency === 'ARS' ? `$ ${formatted}` : `US$ ${formatted}`
    }
  },
  mounted () {
    this.getDisplaysData()
    this.getCampaignsData()
  }
}
</script>