var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can",
              value: "show_statistic_kpi_displays",
              expression: "'show_statistic_kpi_displays'",
            },
          ],
          staticClass: "vx-row",
        },
        _vm._l(_vm.cardsDisplays, function (card, index) {
          return _c(
            "div",
            {
              key: `${card.id}_${index}`,
              staticClass:
                "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 vs-con-loading__container cursor-pointer",
              attrs: { id: card.id },
              on: {
                click: function ($event) {
                  return _vm.showCardsCountries(card.id)
                },
              },
            },
            [
              _c(
                "vx-tooltip",
                { attrs: { color: "dark", text: card.tooltipText } },
                [
                  _c("statistics-card-line", {
                    staticClass: "mb-base",
                    attrs: {
                      hideChart: "",
                      "icon-right": "",
                      icon: card.icon,
                      color: card.color,
                      statistic: card.value,
                      statisticTitle: card.name,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm._l(_vm.cardsDisplays, function (card, index) {
        return _c(
          "div",
          {
            key: `${card.id}_${index}_hidden`,
            staticClass: "vx-row",
            attrs: { id: `${card.id}_country` },
          },
          _vm._l(card.countries, function (element, index2) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: card.vShowCountries,
                    expression: "card.vShowCountries",
                  },
                ],
                key: `${card.id}_${index2}_country`,
                staticClass: "vx-col w-1/2 md:w-1/3 xl:w-1/6",
              },
              [
                _c("statistics-card-line", {
                  staticClass: "mb-base",
                  attrs: {
                    hideChart: "",
                    icon: card.icon,
                    color: card.color,
                    statistic: element.total_count,
                    statisticTitle: element.country,
                  },
                }),
              ],
              1
            )
          }),
          0
        )
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can",
              value: "show_statistic_kpi_campaigns",
              expression: "'show_statistic_kpi_campaigns'",
            },
          ],
          staticClass: "vx-col w-full mb-base",
        },
        [
          _c(
            "vx-tooltip",
            { attrs: { color: "dark", text: _vm.strDateCampaigns } },
            [
              _c(
                "vx-card",
                { attrs: { title: "Campañas" } },
                [
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      _c(
                        "vs-dropdown",
                        {
                          attrs: {
                            "vs-custom-content": "",
                            "vs-trigger-click": "",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "flex items-center",
                              attrs: { "href.prevent": "" },
                            },
                            [
                              _c("span", [_vm._v("Filtrar fechas")]),
                              _c("feather-icon", {
                                attrs: {
                                  icon: "ChevronDownIcon",
                                  svgClasses: "h-4 w-4 ml-1",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "vs-dropdown-menu",
                            [
                              _c("flat-pickr", {
                                staticClass: "mb-1 w-full",
                                attrs: {
                                  width: "100%",
                                  config:
                                    _vm.configDateRangeCampaignsTimePicker,
                                  placeholder: "Rango",
                                },
                                on: {
                                  "on-change": _vm.onDateRangeCampaignsChange,
                                },
                                model: {
                                  value: _vm.dateRangeCampaigns,
                                  callback: function ($$v) {
                                    _vm.dateRangeCampaigns = $$v
                                  },
                                  expression: "dateRangeCampaigns",
                                },
                              }),
                              _c(
                                "vs-dropdown-item",
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "mt-4 w-full",
                                      attrs: {
                                        "vs-trigger-close": "",
                                        width: "100%",
                                        color: "primary",
                                        type: "filled",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getCampaignsData()
                                        },
                                      },
                                    },
                                    [_vm._v("Filtrar")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-row text-center vs-con-loading__container",
                        attrs: { id: "campaigns_stats" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-col w-full lg:w-1/5 md:w-full sm:w-1/5 flex flex-col justify-between mb-4 order-first cursor-pointer",
                            on: {
                              click: function ($event) {
                                _vm.popupActiveCampaigns = true
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6",
                              },
                              [
                                _c(
                                  "h1",
                                  { staticClass: "font-bold text-5xl" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.stats.campaigns_active_count)
                                    ),
                                  ]
                                ),
                                _c("small", [_vm._v("Activas")]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-col w-full lg:w-1/5 md:w-full sm:w-1/5 flex flex-col justify-between mb-4",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6",
                              },
                              [
                                _c(
                                  "h1",
                                  { staticClass: "font-bold text-5xl" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.stats.display_campaigns_active)
                                    ),
                                  ]
                                ),
                                _c("small", [_vm._v("Pantallas")]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-col w-full lg:w-1/5 md:w-full sm:w-1/5 flex flex-col justify-between mb-4",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6",
                              },
                              [
                                _c(
                                  "h1",
                                  { staticClass: "font-bold text-5xl" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.stats
                                          .content_display_campaigns_active
                                      )
                                    ),
                                  ]
                                ),
                                _c("small", [_vm._v("Anuncios")]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-col w-full lg:w-1/5 md:w-full sm:w-1/5 flex flex-col justify-between mb-4",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6",
                              },
                              [
                                _c(
                                  "h1",
                                  { staticClass: "font-bold text-5xl" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.stats.content_display_approved)
                                    ),
                                  ]
                                ),
                                _c("small", [_vm._v("Anuncios Aprobados")]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-col w-full lg:w-1/5 md:w-full sm:w-1/5 flex flex-col justify-between mb-4",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6",
                              },
                              [
                                _c(
                                  "h1",
                                  { staticClass: "font-bold text-5xl" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.stats.content_display_rejected)
                                    ),
                                  ]
                                ),
                                _c("small", [_vm._v("Anuncios No Aprobados")]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can",
              value: "show_statistic_kpi_campaigns_profits",
              expression: "'show_statistic_kpi_campaigns_profits'",
            },
          ],
          staticClass: "vx-row",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container text-success",
            },
            [
              _c(
                "vx-tooltip",
                { attrs: { color: "dark", text: _vm.strDateCampaigns } },
                [
                  _c("statistics-card-line", {
                    staticClass: "mb-base",
                    attrs: {
                      hideChart: "",
                      "icon-right": "",
                      icon: "DollarSignIcon",
                      color: "success",
                      colorStatistic: "success",
                      statistic: _vm.stats.platform_profit_usd,
                      statisticColor: "success",
                      statisticTitle: "Ganancia USD (LatinAd)",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer",
              on: {
                click: function ($event) {
                  _vm.stats.total_price_with_profit_usd_by_country.length > 0
                    ? (_vm.popupTotalPriceWithProfitUsdByCountry = true)
                    : (_vm.popupTotalPriceWithProfitUsdByCountry = false)
                },
              },
            },
            [
              _c(
                "vx-tooltip",
                { attrs: { color: "dark", text: _vm.strDateCampaigns } },
                [
                  _c("statistics-card-line", {
                    staticClass: "mb-base",
                    attrs: {
                      hideChart: "",
                      "icon-right": "",
                      icon: "ShoppingBagIcon",
                      color: "success",
                      statistic: _vm.stats.total_price_with_profit_usd,
                      statisticTitle: "Facturación total USD",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container",
            },
            [
              _c(
                "vx-tooltip",
                { attrs: { color: "dark", text: _vm.strDateCampaigns } },
                [
                  _c("statistics-card-line", {
                    staticClass: "mb-base",
                    attrs: {
                      hideChart: "",
                      "icon-right": "",
                      icon: "CornerUpRightIcon",
                      color: "danger",
                      statistic: _vm.stats.total_price_usd,
                      statisticTitle: "Monto a pagar dueños de medios",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "can",
              rawName: "v-can",
              value: "show_statistic_kpi_campaigns_profits",
              expression: "'show_statistic_kpi_campaigns_profits'",
            },
          ],
          staticClass: "vx-row",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container text-primary",
            },
            [
              _c(
                "vx-tooltip",
                { attrs: { color: "dark", text: _vm.strDateCampaigns } },
                [
                  _c("statistics-card-line", {
                    staticClass: "mb-base",
                    attrs: {
                      hideChart: "",
                      "icon-right": "",
                      icon: "DollarSignIcon",
                      colorStatistic: "primary",
                      statistic: _vm.stats.platform_profit_ars,
                      statisticTitle: "Ganancia ARS",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer",
              on: {
                click: function ($event) {
                  _vm.stats.total_price_with_profit_ars_by_country.length > 0
                    ? (_vm.popupTotalPriceWithProfitArsByCountry = true)
                    : (_vm.popupTotalPriceWithProfitArsByCountry = false)
                },
              },
            },
            [
              _c(
                "vx-tooltip",
                { attrs: { color: "dark", text: _vm.strDateCampaigns } },
                [
                  _c("statistics-card-line", {
                    staticClass: "mb-base",
                    attrs: {
                      hideChart: "",
                      "icon-right": "",
                      icon: "ShoppingBagIcon",
                      statistic: _vm.stats.total_price_with_profit_ars,
                      statisticTitle: "Facturación total ARS",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container",
            },
            [
              _c(
                "vx-tooltip",
                { attrs: { color: "dark", text: _vm.strDateCampaigns } },
                [
                  _c("statistics-card-line", {
                    staticClass: "mb-base",
                    attrs: {
                      hideChart: "",
                      "icon-right": "",
                      icon: "CornerUpRightIcon",
                      color: "danger",
                      statistic: _vm.stats.total_price_ars,
                      statisticTitle: "Monto a pagar dueños de medios",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: "Campañas activas",
            active: _vm.popupActiveCampaigns,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupActiveCampaigns = $event
            },
          },
        },
        [
          _c(
            "vs-table",
            {
              attrs: {
                noDataText: "Sin datos",
                data: _vm.stats.campaigns_active,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _vm._l(data, function (tr, indextr) {
                      return _c(
                        "vs-tr",
                        {
                          key: indextr,
                          attrs: {
                            state: data[indextr].smart_campaign
                              ? "primary"
                              : null,
                          },
                        },
                        [
                          _c("vs-td", { attrs: { data: data[indextr].id } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(data[indextr].id) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", { attrs: { data: data[indextr].name } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(data[indextr].name) +
                                "\n          "
                            ),
                          ]),
                          _c(
                            "vs-td",
                            { attrs: { data: data[indextr].type_campaign } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(data[indextr].type_campaign) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _c(
                            "vs-td",
                            { attrs: { data: data[indextr].start_date } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("dateFormatter")(
                                      data[indextr].start_date
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _c(
                            "vs-td",
                            { attrs: { data: data[indextr].end_date } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("dateFormatter")(
                                      data[indextr].end_date
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _c(
                            "template",
                            { staticClass: "expand-user", slot: "expand" },
                            [
                              _c(
                                "div",
                                { staticClass: "con-expand-users w-full" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "con-btns-user flex items-center justify-between",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "con-userx flex items-center justify-start",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Fecha creación: " +
                                                _vm._s(
                                                  _vm._f("dateFormatter")(
                                                    tr.created_at
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        2
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", [_vm._v("ID")]),
                  _c("vs-th", [_vm._v("Nombre")]),
                  _c("vs-th", [_vm._v("Tipo")]),
                  _c("vs-th", [_vm._v("Fecha inicio")]),
                  _c("vs-th", [_vm._v("Fecha fin")]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: "Facturacion en USD por país",
            active: _vm.popupTotalPriceWithProfitUsdByCountry,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupTotalPriceWithProfitUsdByCountry = $event
            },
          },
        },
        _vm._l(
          _vm.stats.total_price_with_profit_usd_by_country,
          function (value, index) {
            return _c(
              "div",
              {
                key: `total_price_with_profit_usd_by_country_${index}`,
                staticClass: "vx-col w-full vs-con-loading__container",
              },
              [
                _c("statistics-card-line", {
                  staticClass: "mb-base",
                  attrs: {
                    hideChart: "",
                    "icon-right": "",
                    icon: "ShoppingBagIcon",
                    color: "success",
                    statistic: value.sum,
                    statisticTitle: value.country,
                  },
                }),
              ],
              1
            )
          }
        ),
        0
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: "Facturacion en ARS por país",
            active: _vm.popupTotalPriceWithProfitArsByCountry,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupTotalPriceWithProfitArsByCountry = $event
            },
          },
        },
        _vm._l(
          _vm.stats.total_price_with_profit_ars_by_country,
          function (value, index) {
            return _c(
              "div",
              {
                key: `total_price_with_profit_usd_by_country_${index}`,
                staticClass: "vx-col w-full vs-con-loading__container",
              },
              [
                _c("statistics-card-line", {
                  staticClass: "mb-base",
                  attrs: {
                    hideChart: "",
                    "icon-right": "",
                    icon: "ShoppingBagIcon",
                    statistic: value.sum,
                    statisticTitle: value.country,
                  },
                }),
              ],
              1
            )
          }
        ),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }